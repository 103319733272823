<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REQUEST CASKET</h4></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-row v-if="is_new_request">
              <v-col md="3" cols="12">
                <v-text-field
                  v-model="date_of_request"
                  label="Date of Request"
                  dense
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="9">
                <v-select
                  v-model="factory_name"
                  dense
                  outlined
                  label="Casket Factories"
                  :items="factory_items"
                  item-value="name"
                  item-text="name"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col md="4" cols="12"></v-col>
              <v-col md="4" cols="12">
                <!-- alert -->
                <v-col cols="12" v-show="alert_req">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message_req }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-btn color="primary" class="me-3 mt-4" @click="save_request" v-if="!saving_req">
                    Save changes
                  </v-btn>
                  <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                       v-else></v-progress-circular>
                </v-col>
              </v-col>
              <v-col md="4" cols="12"></v-col>
            </v-row>
            <v-row v-else>
              <v-col md="3" cols="12">
                <v-text-field
                  v-model="date_of_request_req"
                  label="Date of Request"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  v-model="factory_name_already_req"
                  label="Casket Factories"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12"></v-col>
              <v-col md="4" cols="12">
                <v-text-field v-model="po_no" label="PO #" dense outlined readonly></v-text-field>
              </v-col>
              <v-col md="4" cols="12"></v-col>
            </v-row>
            <v-row v-if="!is_new_request">
              <v-col md="12" cols="12">
                <v-select
                  v-model="casket_id"
                  dense
                  outlined
                  label="Casket Type"
                  :items="casket_type_items"
                  item-value="id"
                  item-text="casket_type"
                  :rules="rules.combobox_rule"
                  @change="
                    selected_casket(
                      casket_type_items[
                        casket_type_items
                          .map(function (x) {
                            return x.id
                          })
                          .indexOf(casket_id)
                      ],
                    )
                  "
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="number"
                  min="0"
                  v-model="quantity"
                  label="Quantity"
                  dense
                  outlined
                  @change="calculate_total_price"
                  @keyup="calculate_total_price"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-if="is_customize_amount"
                  v-model="casket_amount"
                  label="Casket Amount"
                  dense
                  outlined
                  type="number"
                  min="0"
                  @change="calculate_total_price"
                  @keyup="calculate_total_price"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>

                <v-text-field
                  v-else
                  v-model="casket_amount"
                  label="Casket Amount"
                  dense
                  outlined
                  readonly
                  @change="calculate_total_price"
                  @keyup="calculate_total_price"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="amount_format"
                  label="Total Amount"
                  dense
                  outlined
                  readonly
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12" md="2">
                <v-btn color="primary" class="me-3 mt-4" @click="save_report_casket" v-if="!saving">
                  Save changes
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
              <v-col cols="12" md="2" v-if="this.report_casket_items.length > 0">
                <v-btn color="info" class="me-3 mt-4" @click="done_requested" v-if="!saving"> Done
                  Request
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="info" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
              <v-col cols="12" md="2" v-if="this.report_casket_items.length === 0">
                <v-btn color="info" class="me-3 mt-4" @click="delete_requested" v-if="!saving">
                  Delete
                  Request
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="info" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text v-if="this.report_casket_items.length > 0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Casket type</th>
              <th class="text-center text-uppercase">Quantity</th>
              <th class="text-center text-uppercase">Amount</th>
              <th class="text-center text-uppercase">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in report_casket_items" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.casket_type }}
              </td>
              <td class="text-center">
                {{ item.quantity }}
              </td>
              <td class="text-center">
                {{ item.amount }}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    class="mr-2"
                    color="error"
                    @click="
                        deleted_report_casket(
                          report_casket_items
                            .map(function (x) {
                              return x.id
                            })
                            .indexOf(item.id),
                        )
                      "
                  >
                    {{ icons.mdiDelete }}
                  </v-icon>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiDelete} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_new_request: true,
      date_of_request_req: '',
      date_of_request: '',
      po_no: '',

      saving_req: false,
      alert_req: false,
      alert_message_req: '',

      is_customize_amount: false,

      saving: false,
      alert: false,
      alert_message: '',

      factory_items: [],
      factory_name: '',
      factory_name_already_req: '',

      request_casket_items: [],
      report_casket_items: [],
      casket_type_items: [],
      casket_id: '',
      casket_type: '',
      quantity: 0,
      amount: 0,
      amount_format: 0,
      casket_amount: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiDelete,
        },
      }
    },
    created() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    watch: {
      request_casket_items: function () {
        if (this.request_casket_items.length > 0) {
          this.timeout = setTimeout(() => {
            this.factory_name = this.factory_name_already_req
          }, 100)
        }
      },
      factory_name: function () {
        this.selected_factories(
          this.factory_items[
            this.factory_items
              .map(function (x) {
                return x.name
              })
              .indexOf(this.factory_name)
            ],
        )
      },
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('casket_factory', ['get_casket_factories']),
      ...mapActions('request_casket', [
        'get_request_casket_not_yet_done',
        'save_new_request_casket',
        'done_request_casket',
        'delete_request_casket'
      ]),
      ...mapActions('report_casket', ['save_new_report_casket', 'delete_report_casket']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      initialize_data() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        this.get_casket_factories(data)
          .then(response => {
            this.factory_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
        this.saving_req = true
        this.get_request_casket_not_yet_done(data)
          .then(response => {
            this.saving_req = false
            this.request_casket_items = response.data
            if (this.request_casket_items.length > 0) {
              this.is_new_request = false
              this.date_of_request_req = moment(this.request_casket_items[0].date, 'YYYY-MM-DD').format('MMMM DD, YYYY')
              this.factory_name_already_req = this.request_casket_items[0].name
              this.po_no = this.request_casket_items[0].id
              this.report_casket_items = this.request_casket_items[0].report_casket
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_factories(data) {
        this.casket_type_items = data.data
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_casket(data) {
        this.is_customize_amount = false
        if (data.amount < 1) {
          this.is_customize_amount = true
        }
        this.casket_type = data.casket_type
        this.casket_amount = data.amount
      },
      calculate_total_price() {
        this.amount = parseInt(this.quantity) * parseFloat(this.casket_amount)
        this.amount_format = this.formatPrice(this.amount)
      },
      save_request() {
        this.saving_req = true
        this.alert_req = false
        var flag = false
        if (
          moment(this.date_of_request, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
          moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY') ||
          moment(this.date_of_request, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
          moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')
        ) {
          flag = true
        } else {
          var compareDate = moment(this.date_of_request, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_of_request = ''
          this.alert_req = true
          this.alert_message_req = 'Date is Not Belong in Transaction Month!'
          this.saving_req = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id)
            data.append('user_id', this.user_id)
            data.append('date', moment(this.date_of_request, 'YYYY-MM-DD').format('MMMM D, YYYY'))
            data.append('name', this.factory_name)
            this.save_new_request_casket(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.reset()
              })
              .catch(error => {
                this.alert_req = true
                this.alert_message_req = error
                this.saving_req = false
              })
          } else {
            this.alert_req = true
            this.alert_message_req = 'Please Fill up the field/s'
            this.saving_req = false
          }
        }
      },
      done_requested() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('po_no', this.po_no)
        this.done_request_casket(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            this.alert_req = true
            this.alert_message_req = error
            this.saving_req = false
          })
      },
      delete_requested() {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('po_no', this.po_no)
        this.delete_request_casket(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            this.alert_req = true
            this.alert_message_req = error
            this.saving_req = false
          })
      },
      deleted_report_casket(index) {
        const data = new FormData()
        data.append('id', this.report_casket_items[index].id)
        this.delete_report_casket(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'error',
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      save_report_casket() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id)
          data.append('po_no', this.po_no)
          data.append('casket_type', this.casket_type)
          data.append('quantity', this.quantity)
          data.append('amount', this.amount)
          this.save_new_report_casket(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
